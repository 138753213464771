import * as Sentry from "@sentry/gatsby"

Sentry.init({
  dsn: "https://5ab4de4ac92571366c0f293d54d5625b@sentry.neogeo.fr/21",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/rec\.datasud\.fr\/portal/,
    /^https:\/\/www\.datasud\.fr\/portal/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// const container = document.getElementById(“app”);
// const root = createRoot(container);
// root.render(<App />);
